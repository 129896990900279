import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Metadata, Page } from '~/components/Layout'

const QuizPage = ({ data: { page } }) => (
  <Page>
    <Metadata title={page.name} />
    <div
      className="octane-ai-quiz"
      data-quiz-id="M9NFBGbDLaHQNXTY"
      data-embed-type="fullpage"
      data-mobile-height="match_browser"
      data-desktop-height="match_browser"
      id="02752226cb97c315dd083257dc76f3936d26b8d0"
    ></div>
  </Page>
)

export default QuizPage

export const query = graphql`
  query QuizPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "which-cookware-color-are-you" }
      node_locale: { eq: $locale }
    ) {
      name
    }
  }
`

QuizPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  })
}
